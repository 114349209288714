<template>
  <div class="container__content">
    <BaseModal
      class="modal"
      v-if="sent"
    >
      <div class="back">
        <img :src="require('@/assets/back.svg')" @click="onCloseModal" />
      </div>
      <div class="message">
        <img :src="require('@/assets/send-mail.svg')" />
        <div class="message__text--title">
          Check your email!
        </div>
        <div class="message__text--regular">
          To confirm your email, click on the button in the email we sent you to
          <strong>{{ email }}</strong>
        </div>
      </div>
    </BaseModal>
    <div class="header">
      <img :src="require('@/assets/mycontact.svg')" width="205" />
      <div class="header__title">
        So glad you're here!
      </div>
    </div>
    <MagicLink
      class="magic-link"
      v-model="email"
      @next="onNext"
    />
    <div class="privacy-notice">
      By logging in, you agree to our
      <a target="_blank"
        href="https://logopost.es/politica-de-privacidad/">
        Privacy Policy
      </a>
    </div>
    <div class="bottom-notice">
      <img class="bottom-notice__image" :src="require('@/assets/magic-wand.svg')" />
      <div class="bottom-notice__message">
        We will send you a magic link by email so you can login without a password.
      </div>
    </div>
  </div>
</template>

<script>
import BaseModal from '../components/BaseModal/BaseModal.vue';
import MagicLink from '../components/MagicLink.vue';

export default {
  name: 'Home',
  components: { MagicLink, BaseModal },
  data: () => ({
    email: '',
    sent: false,
  }),
  methods: {
    onNext() {
      this.$store.dispatch('authRequest', this.email)
        .then(() => {
          this.sent = true;
        });
    },
    onCloseModal() {
      this.sent = false;
      this.email = '';
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/style/organisms/container.sass"
@import "@/style/pages/FreeSignin"
</style>
