import Company from '../domain/models/Company';
import Headquarter from '../domain/models/Headquarter';
import InputImage from '../domain/models/InputImage';
import User from '../domain/models/User';

class UserFreeTransformer {
  static fetch(user) {
    return new User({
      id: user.id,
      username: user.userName,
      first_name: user.firstName,
      last_name: user.lastName,
      role: user.role,
      phone: user.phone,
      mail: user.email,
      linkedin: user.linkedIn,
      bio: user.bio,
      avatar: new InputImage({
        url: user.avatar,
        data: '',
        extension: '',
      }),
      company: new Company({
        company_url: user.web,
        name: user.company,
        primary_color: user.primaryColor,
        logo: new InputImage({
          url: user.logo,
          data: '',
          extension: '',
        }),
      }),
      head_quarter: new Headquarter({
        address: user.address,
      }),
      updatedAt: user.updatedAt,
    });
  }
}

export default UserFreeTransformer;
