<template>
  <div class="container__content">
    <div class="top-bar" :class="{ hidden: !showForm }">
      <div class="top-bar__back">
        <img
          :src="require('@/assets/back.svg')"
          @click="onGoTo('free-home-card')"
          v-if="!isEmpty"
        />
      </div>
      <div class="top-bar__title">
        Edit card
      </div>
      <div class="top-bar__save">
        <a class="link link-strong" href="#" @click.prevent="onSave">Save</a>
      </div>
    </div>
    <div class="form" :class="{ hidden: !showForm }">
      <div class="form__inputs">
        <InputAvatar
          :value="avatar"
          @change="onChangeImage('avatar', $event)"
          @shown="showForm=!showForm"
          @hidden="showForm=!showForm"
        />
        <Input
          label="First Name"
          :hasError="errorsInForm.firstName"
          v-model="user.first_name"
        />
        <Input
          label="Last Name"
          :hasError="errorsInForm.lastName"
          v-model="user.last_name"
        />
        <Input
          label="Company"
          :hasError="errorsInForm.company"
          v-model="user.company.name"
        />
        <Input
          label="Role"
          :hasError="errorsInForm.role"
          v-model="user.role"
        />
        <Textarea
          label="Hello message"
          :hasError="errorsInForm.bio"
          v-model="user.bio"
        />
        <div class="form__subtitle">
          Contact info
        </div>
        <Input
          label="Email"
          type="email"
          disabled
          v-model="user.mail"
        />
        <Input
          label="Web"
          type="url"
          :hasError="errorsInForm.web"
          v-model="user.company.company_url"
        />
        <Input
          label="LinkedIn profile"
          type="url"
          :hasError="errorsInForm.linkedIn"
          v-model="user.linkedin"
        />
        <Input
          label="Phone number"
          type="tel"
          :hasError="errorsInForm.phone"
          v-model="user.phone"
        />
        <Input
          label="Address"
          :hasError="errorsInForm.address"
          v-model="user.head_quarter.address"
        />
        <div class="form__subtitle">
          Brand
        </div>
        <InputColor
          label="Brand color"
          :hasError="errorsInForm.primaryColor"
          v-model="user.company.primary_color"
        />
        <InputLogo
          :value="logo"
          label="Logo"
          @change="onChangeImage('logo', $event)"
        />
        <div class="form__buttons">
          <Submit
            @click="onSave"
          >
            Save
          </Submit>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from '@/components/Form/Input.vue';
import Textarea from '@/components/Form/Textarea.vue';
import Submit from '@/components/Form/Submit.vue';
import InputColor from '@/components/Form/InputColor.vue';
import InputAvatar from '@/components/Form/InputAvatar.vue';
import InputImage from '@/domain/models/InputImage';
import InputLogo from '@/components/Form/InputLogo.vue';
import SaveableUserTransformer from '@/transformers/SaveableUserTransformer';

export default {
  components: {
    Input, Textarea, Submit, InputColor, InputAvatar, InputLogo,
  },
  data: () => ({
    errorsInForm: {},
    isEmpty: true,
    image: null,
    showForm: true,
    user: {
      first_name: '',
      last_name: '',
      company: {
        company_url: '',
      },
      role: '',
      bio: '',
      mail: '',
      linkedin: '',
      phone: '',
      address: '',
      color: '',
      avatar: {
        url: null,
        data: '',
        extension: '',
      },
      logo: {
        url: null,
        data: '',
        extension: '',
      },
    },
    avatar: null,
    logo: null,
  }),
  created() {
    this.user = { ...this.$store.getters.user };
    this.avatar = new InputImage(this.user.avatar);
    this.logo = new InputImage(this.user.company.logo);
    this.user.logo = new InputImage(this.user.company.logo);
    this.isEmpty = this.$store.getters.user.isEmpty();
  },
  methods: {
    onSave() {
      const user = SaveableUserTransformer.fetch(this.user);
      this.$store.dispatch('saveUserFree', user)
        .then(() => {
          this.$router.push({
            name: 'free-view-card',
            params: {
              user_id: this.user.id,
            },
          });
        })
        .catch((error) => {
          if (!error.errors) {
            return;
          }
          const totalErrors = {};
          error.errors.forEach((errorItem) => {
            totalErrors[errorItem.field] = true;
          });
          this.errorsInForm = totalErrors;
        });
    },
    onGoTo(route) {
      this.$router.push({ name: route });
    },
    onChangeImage(key, image) {
      this.user[key] = image;
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/style/organisms/container.sass"
@import "@/style/pages/FreeEditCard.sass"
</style>
