export default class User {
  constructor(user) {
    this.id = user.id;
    this.first_name = user.first_name;
    this.last_name = user.last_name;
    this.role = user.role;
    this.phone = user.phone;
    this.mail = user.mail;
    this.linkedin = user.linkedin;
    this.bio = user.bio;
    this.avatar = user.avatar;
    this.mail = user.mail;
    this.company = user.company;
    this.head_quarter = user.head_quarter;
    this.updatedAt = user.updatedAt;
  }

  get getId() {
    return this.id;
  }

  get getUsername() {
    return this.username;
  }

  get getFirstName() {
    return this.first_name;
  }

  get getLastName() {
    return this.last_name;
  }

  get getName() {
    return `${this.first_name} ${this.last_name}`;
  }

  get getRole() {
    return this.role;
  }

  get getPhone() {
    return this.phone;
  }

  get getLinkedin() {
    if (this.linkedin && !this.linkedin.match(/^http/)) {
      return `https://${this.linkedin}`;
    }
    return this.linkedin;
  }

  get getBio() {
    return this.bio;
  }

  get getAvatar() {
    return this.avatar;
  }

  get getMail() {
    return this.mail;
  }

  get getHeadquarter() {
    return this.head_quarter;
  }

  get getCompany() {
    return this.company;
  }

  get getUpdatedAt() {
    return new Date(this.updatedAt);
  }

  getUpdatedAtTimestamp() {
    return this.getUpdatedAt.getTime().toString();
  }

  isEmpty() {
    return !this.getFirstName || this.getFirstName.trim() === '';
  }
}
